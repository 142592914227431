* {
    box-sizing: border-box;
    
  }
    
  html,
  body {
    font-family: 'Poppins';
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  
  
  a {
    text-decoration: none;
  }
  
  a,
  a:visited {
    color: inherit;
  }
  
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
