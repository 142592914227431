.card {
  width: 100%;
  height: 100%;
  margin: auto;
  min-height: 32em; /* 512px/16 */
  box-shadow: 0 2.1875em 4.375em 0 rgba(45, 45, 45, 0.65), 0 1.875em 2.5em -0.625em rgba(30, 30, 30, 0.55);
  background: linear-gradient(180deg, rgba(50, 50, 50, 1) 0%, rgba(25, 25, 25, 1) 100%);
  background: 
    linear-gradient(180deg, rgba(50, 50, 50, 0.6) 0%, rgba(25, 25, 25, 0.8) 100%),
    /* url('https://wallpapers.com/images/featured/simple-clean-8g9017acqfddycrl.jpg'); */
    /* url('https://wallpaperaccess.com/full/4718362.jpg') */
    url('https://images.hdqwalls.com/download/flat-minimal-morning-landscape-lake-5k-cp-2560x1440.jpg');
  background-size: cover;
  border-radius: 0; /* 10px/16 */
  display: flex;
  flex-direction: column;
  background-position: bottom;
}

@media screen and (max-width: 32em) { /* 512px/16 */
  .card {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
  }
}

.header {
  display: flex;
  padding: 3em; /* 48px/16 */
  padding-bottom: 0.25em; /* 4px/16 */
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-bottom: 1.25em; /* 20px/16 */
}

.logo {
  font-weight: bold;
  font-size: 2em;
  transition: opacity 0.05s ease;
}

.logo img {
  padding: 0.9375em; /* 15px/16 */
  height: 7.375em; /* 150px/16 */
  max-width: 100%;
  display: block;
}

.logo:hover {
  opacity: 0.75;
}

.social {
  display: flex;
}

.social a {
  display: inline-block;
  margin-right: 0.75em; /* 12px/16 */
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.75;
}

.social .icon {
  width: 2.8125em; /* 45px/16 */
  fill: #fff;
}

.content {
  flex: 1 1 auto;
  min-height: 16em; /* 256px/16 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 1.875em; /* 30px/16 */
}

.content .title-holder {
  color: #fff;
  text-align: center;
  margin-bottom: 1.5em; /* 24px/16 */
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 1.8em; /* 36px/16 */
  margin-bottom: 0.75em; /* 12px/16 */
}

.content .title-holder p {
  font-size: 1em; /* 16px/16 */
  line-height: 1.75em; /* 28px/16 */
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 48em) { /* 768px/16 */
  .content .title-holder {
      max-width: 80%;
  }
}

.content .cta {
  min-width: 4em; /* 64px/16 */
  padding: 1em 1.5em; /* 16px/24px */
  background: #fff;
  border-radius: 3.125em; /* 50px/16 */
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0 0.625em 1.875em 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content .cta:hover {
  transform: translateY(-0.0625em); /* 1px/16 */
  box-shadow: 0 0.625em 2.5em 0 rgba(0, 0, 0, 0.3);
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 2.5em; /* 48px/16 */
  justify-content: center;
  color: #fff;
  font-size: 0.875em; /* 14px/16 */
  font-weight: 500;
  text-align: center;
}

a.underlined:hover {
  border-bottom: 0.0625em dotted #fff; /* 1px/16 */
}

